import axios from 'axios';
import defaultEnpoints from "../Endpoints";
import AuthService from "./AuthService";
import * as BM from '../types/backend.types';
import qs from "qs";

const endpoint = defaultEnpoints.roomsEndpoint;
const baseEndpoint = defaultEnpoints.baseEndpoint;

const entityName = "/session"

class SessionsService extends AuthService {

  constructor() {
    super();
  }


  getSessionById(congressId:string, sessionId:string) {
    let promise = axios.get<any, BM.UserResponse>(baseEndpoint+congressId+entityName+"/"+sessionId, {'headers':  this.getLoggedInHeaders()});
    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
  }

  getCongressSessions(congressId:string) {
    let promise = axios.get<any, BM.UserResponse>(baseEndpoint+congressId+entityName, {'headers':  this.getLoggedInHeaders()});
    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
  }


  addSession(congressId:string, body:string) {
    let promise = axios.post<any, BM.UserResponse>(endpoint+congressId+entityName, body, {'headers':  this.getLoggedInHeaders()});
    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
  }


  editSession(congressId:string, body:string) {
    let promise = axios.put<any, BM.UserResponse>(endpoint+congressId+entityName, body, {'headers':  this.getLoggedInHeaders()});
    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
  }

  deleteSession(congressId:string, id:string) {
    let body=[id];

    console.log(`deleteRoom  - ${JSON.stringify(body)}`)

    let  promise = axios.delete<any, BM.UserResponse>(endpoint+congressId+entityName,  {'data':JSON.stringify(body), 'headers':  this.getLoggedInHeaders()});
    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
  }


  startSession(congressId:string, sessionId:string) {
    let promise = axios.put<any, BM.UserResponse>(endpoint+congressId+entityName+"/"+sessionId+"/start", "", {'headers':  this.getLoggedInHeaders()});
    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
  }

  stopSession(congressId:string, sessionId:string) {
    let promise = axios.put<any, BM.UserResponse>(endpoint+congressId+entityName+"/"+sessionId+"/stop", "", {'headers':  this.getLoggedInHeaders()});
    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
  }


  stopSurveySubmission(congressId:string, sessionId:string, surveyId:string) {
    let promise = axios.put<any, BM.UserResponse>(endpoint+congressId+entityName+"/"+sessionId+"/survey/"+surveyId+"/stop", "", {'headers':  this.getLoggedInHeaders()});
    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
  }

  resumeSurveySubmission(congressId:string, sessionId:string, surveyId:string) {
    let promise = axios.put<any, BM.UserResponse>(endpoint+congressId+entityName+"/"+sessionId+"/survey/"+surveyId+"/resume", "", {'headers':  this.getLoggedInHeaders()});
    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
  }

  resetSurveySubmission(congressId:string, sessionId:string, surveyId:string) {
    let promise = axios.put<any, BM.UserResponse>(endpoint+congressId+entityName+"/"+sessionId+"/survey/"+surveyId+"/reset", "", {'headers':  this.getLoggedInHeaders()});
    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
  }

  submitSurveyToSession(congressId:string, sessionId:string, surveyId:string, body:string, addPendingGeneration:boolean=false) {
  let query = qs.stringify({addPendingGeneration});

    let promise = axios.put(endpoint+congressId+entityName+"/"+sessionId+"/survey/"+surveyId+"/submit?"+query, body, {'headers':  this.getLoggedInHeaders()});

    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
    return
  }

  submitClinicCaseQuestionToSession(congressId: string, sessionId: string, clinicCaseQuestionId: string, body: string[]) {
    let promise = axios.put(endpoint+congressId+entityName+"/"+sessionId+"/clinicCaseQuestion/"+clinicCaseQuestionId+"/submit", body, {'headers':  this.getLoggedInHeaders()});

    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
    return
  }

  showResultClinicCaseQuestionToSession(congressId: string, sessionId: string, clinicCaseQuestionId: string) {
    let promise = axios.put(endpoint+congressId+entityName+"/"+sessionId+"/clinicCaseQuestion/"+clinicCaseQuestionId+"/showResults", undefined, {'headers':  this.getLoggedInHeaders()});

    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
    return
  }

  stopClinicCaseQuestionToSession(congressId: string, sessionId: string, clinicCaseQuestionId: string) {
    let promise = axios.put(endpoint+congressId+entityName+"/"+sessionId+"/clinicCaseQuestion/"+clinicCaseQuestionId+"/stop", undefined, {'headers':  this.getLoggedInHeaders()});

    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
    return
  }

  resumeClinicCaseQuestionToSession(congressId: string, sessionId: string, clinicCaseQuestionId: string) {
    let promise = axios.put(endpoint+congressId+entityName+"/"+sessionId+"/clinicCaseQuestion/"+clinicCaseQuestionId+"/resume", undefined, {'headers':  this.getLoggedInHeaders()});

    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
    return
  }

  resetClinicCaseQuestionToSession(congressId: string, sessionId: string, clinicCaseQuestionId: string) {
    let promise = axios.put(endpoint+congressId+entityName+"/"+sessionId+"/clinicCaseQuestion/"+clinicCaseQuestionId+"/reset", undefined, {'headers':  this.getLoggedInHeaders()});

    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
    return
  }

  getUsersSubscribedToSession(congressId: string, sessionId: string) {
    let promise = axios.get(`${endpoint}${congressId}/session/${sessionId}/subscribed_users`, {'headers':  this.getLoggedInHeaders()});

    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
  }

  getSessionMessages(congressId: string, sessionId: string) {
    let promise = axios.get(`${endpoint}${congressId}/session/${sessionId}/message`, {'headers':  this.getLoggedInHeaders()});

    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
  }

  editSessionMessages(congressId: string, sessionId: string, message: string) {
    let promise = axios.put(`${endpoint}${congressId}/session/${sessionId}/message`, message, {'headers':  this.getLoggedInHeaders()});

    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
  }


}

export default SessionsService;
